.listItem {
    cursor: pointer;
}
.listItem:hover {
    background-color: #2d3331;
}
.activeListItem {
    background-color: #0f1110;
    border-left: 4px solid #f08026;
}
.rewardTitle {
    text-overflow: ellipsis;
}