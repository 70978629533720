.alertboxWrapper {
    background: rgba(0,0,0,99%);
    width: 1000px;
    height: 250px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid;
    border-radius: 15px;
    overflow: hidden;
}
.triangle {
    position: absolute;
    width: 120px;
    height: 120px;
    transform: rotate(45deg);
    bottom: -60px;
    left: -60px;
}
.icon {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 5px;
    left: 5px;
    background-image: url("../../../../public/img/icon.svg");
    background-repeat: no-repeat;
}
.logo {
    position: absolute;
    height: 65px;
    width: 186px;
    top: 10px;
    right: 15px;
    background-image: url("../../../../public/img/logo.png");
    background-repeat: no-repeat;
}
.alertImageWrapper {
    height: 150px;
    min-width: 150px;
    width: 150px;
    border: 4px solid;
    transform: rotate(45deg);
    border-radius: 15px;
    overflow: hidden;
}
.alertImage {
    height: 150px;
    width: 150px;
    transform: rotate(-45deg) scale(1.4);
    border-radius: 15px;
}
.alertTitle {
    font-family: Bebas !important;
    font-size: 2.5rem !important;
}
.alertText {
    font-family: Gotham !important;
    font-size: 2.6rem !important;
    line-height: 3rem !important;
}
.textContainer {
    width: 100%;
    word-wrap: break-word;
}