.overlayWrapper {
    width: 1920px;
    height: 1080px;
    background: transparent;
}
.errorBox {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: darkred;
}